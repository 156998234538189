import Box from "@gtg/components/box";
import Container from "@gtg/components/container";
import Seo from "@gtg/components/seo";
import media from "@gtg/utils/media-queries";
import React from "react";
import styled from "styled-components";
import ArticleLayout from "../components/articleLayout";
import Category from "../components/category";
import GlobalWrapper from "../components/globalWrapper";
import Subtitle from "../components/subtitle";
import ScribbleImageUrl from "../images/scribble.svg";

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const H2 = styled.h2`
  margin-top: 4rem;
  ${media.md`
    margin-top: 3rem;
  `}
  ${media.xs`
    margin-top: 2rem;
  `}
`;
const Red = styled.span`
  color: #ff8078;
`;

const Scribble = styled.div`
  background: url(${ScribbleImageUrl}) no-repeat;
  background-size: contain;
  width: 236px;
  height: 26px;
  position: absolute;
  bottom: -40px;
  left: 200px;
  ${media.xl`
    left: 130px;
    width: 180px;
  `}
  ${media.lg`
    bottom: -35px;
    width: 120px;
    left: 100px;
  `}
  ${media.md`
    display: none;
  `}
`;
const ArticlePage = () => {
  return (
    <GlobalWrapper>
      <Seo />
      <ArticleLayout>
        <Container wMax="sm">
          <Box px={[0, 0, 0, 3, 2, 1]} pyMax="lg">
            <Category>Jobs</Category>
            <hr />
            <h1>
              {" "}
              Das neue <Red>Zuhause</Red> für deine Karriere.
            </h1>
            <Subtitle>Offene Stellen bei Lenzbox</Subtitle>
            <Img src="" />
            <p>
              <Scribble />
            </p>
            <p>
              
              Werde jetzt Teil unserer Erfolgsgeschichte. Wir freuen uns auf Deine Initiativbewerbung an <a href="mailto:job@lenzbox.de">job@lenzbox.de</a>. 
            </p>
          </Box>
        </Container>
      </ArticleLayout>
    </GlobalWrapper>
  );
};

export default ArticlePage;
